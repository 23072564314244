import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
import MiniLayout from "../../components/minimalLayout"
import styles from "../../components/minimalLayout.module.css"


const ChatroomTutorial = ({data}) => {

  return (
    <Layout>

      <MiniLayout>
        <h2>Tutorial: Build a chat room with <a href="https://socket.io" target="_blank">socket.io</a></h2>
        <p>This tutorial guides you through building the below chat website.</p>
        <p>The tutorial uses Socket.io Version 3. However you will find the version has changed to 4 by now. The changes however shouldn't be too big. Try to use version 4 as you follow this tutorial.</p>

        <p>Here is a <a target="_blank" href="https://abc-talk-online.glitch.me/">Live Demo</a></p>

        <p>The tutorial is split into a CSS and a JS (socket.io) section.</p>
        <p>Part 1: <a target="_blank" href="https://drive.google.com/file/d/1Em6KhQ2VCuCp6BfquXRNYl6n5noz7brN/view?usp=sharing">Styling</a> (CSS)</p>
        <p>Part 2: <a target="_blank" href="https://drive.google.com/file/d/1t1Ky12ft1UVeUcvxU2dSyOpECNBRiPci/view?usp=sharing">Communicating</a> (JS, Node, SocketIO)</p>
        <br></br>
        <p>When you are done, make sure to put it on Glitch and share it with your friends. Your own, private chat room! ✨</p>
        <br></br>


        <img className={styles.image100} src="/chatroom-tutorial.gif"></img>

      </MiniLayout>
    </Layout>
  )
}



export default ChatroomTutorial
